import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ConfirmPassword = React.lazy(() => import('../pages/auth/ConfirmPassword'));

//users
const UsersPage = React.lazy(()=> import('../pages/users/UserList'));
const UserDetails = React.lazy(()=> import('../pages/users/CreateUser'));
const OrdersPage = React.lazy(()=> import('../pages/users/Orders'));
const OrderDetails = React.lazy(()=> import('../pages/users/CreateOrder'));
const ProductsPage = React.lazy(()=> import('../pages/users/Products'));
const ProductDetails = React.lazy(()=> import('../pages/users/CreateProduct'));
const MappedOrders = React.lazy(()=> import('../pages/users/mappedOrder'));
const MapPage = React.lazy(()=> import('../pages/users/CreateMap'));
const EditMapPage = React.lazy(()=> import('../pages/users/EditMap'));
const TimeSheets = React.lazy(()=> import('../pages/users/timeSheets'));
const EditSheets = React.lazy(()=> import('../pages/users/editSheet'));
const ViewSheets = React.lazy(()=> import('../pages/users/viewSheet'));
const ManagerSheets = React.lazy(()=> import('../pages/users/managerSheet'));
const AdhocSheets = React.lazy(()=> import('../pages/users/AdhocTimeSheets'));
const SkuDetails = React.lazy(()=> import('../pages/users/CreateSku'));
const SkusPage = React.lazy(()=> import('../pages/users/Skus'));
const LeaveDetails= React.lazy(()=> import('../pages/users/CreateLeave'));
const EnvPage = React.lazy(()=> import('../pages/users/envPage'));
const DailyWages = React.lazy(()=> import('../pages/users/dailyWages'));
const CompanyDetails = React.lazy(()=> import('../pages/users/CreateCompany'));
const CompaniesPage = React.lazy(()=> import('../pages/users/Companies'));
const ExpenseDetails= React.lazy(()=> import('../pages/users/CreateExpense'));
const ExpensesPage = React.lazy(()=> import('../pages/users/Expenses'));
const Invoice = React.lazy(()=> import('../pages/users/Invoice'));
const InvoiceDetails = React.lazy(()=> import('../pages/users/InvoiceDetails'));
const Holidays = React.lazy(()=> import('../pages/users/Holidays'));
const ProductList = React.lazy(()=> import('../pages/users/ProductList'));
const Reports = React.lazy(()=> import('../pages/users/Report'));
const SubReports = React.lazy(()=> import('../pages/users/sub_report'));
const ZohoReports = React.lazy(()=> import('../pages/users/zoho_reports'));
const WageReports = React.lazy(()=> import('../pages/users/wage_reports'));
const ProfitReports = React.lazy(()=> import('../pages/users/profit_reports'));
// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
		<Route {...rest}
			render={props => {
				if (!isUserAuthenticated()) {
					return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
				}
				const loggedInUser = getLoggedInUser();
				let role = loggedInUser?loggedInUser.scope.toLowerCase() === 'admin'?'manager':loggedInUser.scope.toLowerCase():null
				return <Component {...props} role={role}/>;
			}} />
);

// root routes
const rootRoute = {
	path: '/',
	exact: true,
	component: (props) => props.role==="sub-contractor"?<Redirect to='/sub_contractor/timesheets' />:props.role==='customer'?<Redirect to='/customer/orders' />:<Redirect to='/manager/users_list' />,
	route: PrivateRoute,
};


const managerRoutes = {
	path : '/manager',
	name : 'Dashboard',
	children:[
		{
			path: '/manager/users_list',
			name: 'Users List',
			component: UsersPage,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/product_list',
			name: 'Products',
			component: ProductsPage,
			route: PrivateRoute,
			key: 'manager',
		},		
		{
			path: '/manager/orders',
			name: 'Requests',
			component: OrdersPage,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/mapped_orders',
			name: 'Mapped Requests',
			component: MappedOrders,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/sku_list',
			name: 'Skus',
			component: SkusPage,
			route: PrivateRoute,
			key: 'manager',
		},		
		{
			path: '/manager/createleave',
			name: 'Leaves',
			component: LeaveDetails,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/timesheets',
			name: 'Timesheets',
			component: TimeSheets,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/env_list',
			name: 'Environment Variables',
			component: EnvPage,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/wages',
			name: 'Weekly Wages',
			component: DailyWages,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/companies_list',
			name: 'Companies',
			component: CompaniesPage,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/expense_list',
			name: 'Expenses',
			component: ExpensesPage,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/invoices',
			name: 'Invoices',
			component: Invoice,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/holidays',
			name: 'Holidays',
			component: Holidays,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/reports',
			name: 'Sales Reports',
			component: Reports,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/sub_reports',
			name: 'SubContractor Reports',
			component: SubReports,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/zoho_reports',
			name: 'Zoho Reports',
			component: ZohoReports,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/wage_reports',
			name: 'Expenses Reports',
			component: WageReports,
			route: PrivateRoute,
			key: 'manager',
		},
		{
			path: '/manager/ps_reports',
			name: 'Profit And Loss Reports',
			component: ProfitReports,
			route: PrivateRoute,
			key: 'manager',
		},
	],
	key: 'manager',
}

const createUser = {
	path: '/manager/create_user',
	name: 'Create User',
	component: UserDetails,
	route: PrivateRoute,
}

const createProduct = {
	path: '/manager/create_product',
	name: 'Create Product',
	component: ProductDetails,
	route: PrivateRoute,
}

const createOrder = {
	path: '/manager/create_order',
	name: 'Create Request',
	component: OrderDetails,
	route: PrivateRoute,
}

const createMap = {
	path: '/manager/create_map',
	name: 'Create Map',
	component: MapPage,
	route: PrivateRoute,
}

const createSku = {
	path: '/manager/create_sku',
	name: 'Create Sku',
	component: SkuDetails,
	route: PrivateRoute,
}

const createCompany ={
	path: '/manager/create_company',
	name: 'Create Company',
	component: CompanyDetails,
	route: PrivateRoute,
}

const createExpense = {
	path: '/manager/create_expense',
	name: 'Create Expense',
	component: ExpenseDetails,
	route: PrivateRoute,
}



const invoceDetailsRoute = {
	path: '/manager/invoices/:Id',
	exact: true,
	component: InvoiceDetails,
	route: PrivateRoute,
};

const editMSheetRoute = {
	path: '/manager/edit_sheet/:Id',
	exact: true,
	component: ManagerSheets,
	route: PrivateRoute,
};

const viewMSheetRoute = {
	path: '/manager/view_sheet/:Id',
	exact: true,
	component: ViewSheets,
	route: PrivateRoute,
};

const editUserRoute = {
	path: '/manager/edit_user/:Id',
	exact: true,
	component: UserDetails,
	route: PrivateRoute,
};

const editMOrderRoute = {
	path: '/manager/edit_order/:Id',
	exact: true,
	component: OrderDetails,
	route: PrivateRoute,
};

const editProductRoute = {
	path: '/manager/edit_product/:Id',
	exact: true,
	component: ProductDetails,
	route: PrivateRoute,
};

const editMapRoute = {
	path: '/manager/edit_mapping/:Id',
	exact: true,
	component: EditMapPage,
	route: PrivateRoute,
};

const editSkuRoute = {
	path: '/manager/edit_sku/:Id',
	exact: true,
	component: SkuDetails,
	route: PrivateRoute,
};

const editCompanyRoute = {
	path: '/manager/edit_company/:Id',
	exact: true,
	component: CompanyDetails,
	route: PrivateRoute,
};

const editExpenseRoute = {
	path: '/manager/edit_expense/:Id',
	exact: true,
	component: ExpenseDetails,
	route: PrivateRoute,
};

const contractorRoutes = {
	path : '/sub_contractor',
	name : 'Dashboard',
	children:[
		{
			path: '/sub_contractor/timesheets',
			name: 'Timesheets',
			component: TimeSheets,
			route: PrivateRoute,
			key: 'sub-contractor',
		},
		{
			path: '/sub_contractor/create_timesheet',
			name: 'Adhoc Timesheets',
			component: AdhocSheets,
			route: PrivateRoute,
			key: 'sub-contractor',
		},
		{
			path: '/sub_contractor/createleave',
			name: 'Leaves',
			component: LeaveDetails,
			route: PrivateRoute,
			key: 'sub-contractor',
		},
		{
			path: '/sub_contractor/product_list',
			name: 'Products',
			component: ProductList,
			route: PrivateRoute,
			key: 'sub-contractor',
		},
	],
	key: 'sub-contractor',
}

const editSheetRoute = {
	path: '/sub_contractor/edit_sheet/:Id',
	exact: true,
	component: EditSheets,
	route: PrivateRoute,
};

const viewSheetRoute = {
	path: '/sub_contractor/view_sheet/:Id',
	exact: true,
	component: ViewSheets,
	route: PrivateRoute,
};

 
const customerRoutes = {
	path : '/customer',
	name : 'Dashboard',
	children:[
		{
			path: '/customer/orders',
			name: 'Requests',
			component: OrdersPage,
			route: PrivateRoute,
			key: 'customer',
		},
		{
			path: '/customer/create_order',
			name: 'Create Request',
			component: OrderDetails,
			route: PrivateRoute,
			key: 'customer',
		},
		{
			path: '/customer/timesheets',
			name: 'Timesheets',
			component: TimeSheets,
			route: PrivateRoute,
			key: 'customer',
		},
	],
	key: 'customer',
}

const editOrderRoute = {
	path: '/customer/edit_order/:Id',
	exact: true,
	component: OrderDetails,
	route: PrivateRoute,
};

const viewCustSheetRoute = {
	path: '/customer/view_sheet/:Id',
	exact: true,
	component: ViewSheets,
	route: PrivateRoute,
};

// auth
const authRoutes = {
	path: '/account',
	name: 'Auth',
	children: [
		{
			path: '/account/login',
			name: 'Login',
			component: Login,
			route: Route,
			key: 'global',
		},
		{
			path: '/account/logout',
			name: 'Logout',
			component: Logout,
			route: Route,
			key: 'global',
		},
		{
			path: '/account/confirm',
			name: 'Confirm',
			component: Confirm,
			route: Route,
			key: 'global',
  	},
		{
			path: '/account/forget-password',
			name: 'Forget Password',
			component: ForgetPassword,
			route: Route,
			key: 'global',
		},
		{
			path: '/account/reset-password/:identifier',
			name: 'Reset Password',
			component: ConfirmPassword,
			route: Route,
			key: 'global',
		},
	],
	key: 'global',
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
	let flatRoutes = [];
	routes = routes || [];
	routes.forEach(item => {
		flatRoutes.push(item);
		if (typeof item.children !== 'undefined') {
			flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
		}
	});
	return flatRoutes;
};

// All routes
const allRoutes = [rootRoute,editUserRoute,editMOrderRoute,editCompanyRoute,editExpenseRoute,invoceDetailsRoute, editOrderRoute, editSkuRoute,editProductRoute,viewMSheetRoute,editMapRoute,editSheetRoute,editMSheetRoute,viewSheetRoute,viewCustSheetRoute,createUser,createProduct,createOrder,createSku,createCompany,createExpense,createMap, authRoutes, managerRoutes, contractorRoutes, customerRoutes];

const authProtectedRoutes = [rootRoute,editUserRoute,editMOrderRoute,editCompanyRoute,editExpenseRoute,invoceDetailsRoute, editOrderRoute,editProductRoute,viewMSheetRoute,editMapRoute,editSheetRoute,viewSheetRoute,editMSheetRoute,viewCustSheetRoute,createUser,createProduct,createOrder,createSku,createCompany,createExpense,createMap, managerRoutes, contractorRoutes, customerRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
