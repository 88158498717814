// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
	// console.log("*******************")
	const user = getLoggedInUser();
	// console.log(user)
	if (!user) { return false;}	 
	else{ return user}
	// const currentTime = Date.now() / 1000;
	// if (user.exp < currentTime) {
	// 	console.warn('access token expired');
	// 	return false;
	// } else {
	// 	return true;
	// }
};


/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
	const cookies = new Cookies();
	const user = cookies.get('user');
	if(user){
		if(typeof user !== 'object'){JSON.parse(user)}
		const decoded = jwtDecode(user.token);
		let jwt = user.token
		let jwtData = jwt.split(".")[1];
		let decodedJwtJsonData = window.atob(jwtData);
		let decodedJwtData = JSON.parse(decodedJwtJsonData);
		decodedJwtData['token'] = user.token
		return decodedJwtData
	}else{
		return null
	}
	return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export { isUserAuthenticated, getLoggedInUser };
