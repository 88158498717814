// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD, SOCIAL_LOGIN_USER } from './constants';
import {LOCAL_URI} from '../../constants';
import {
	loginUserSuccess,
	loginUserFailed,
	registerUserSuccess,
	registerUserFailed,
	forgetPasswordSuccess,
	forgetPasswordFailed,
} from './actions';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = user => {
	// console.log(user,"setSession")
	let cookies = new Cookies();
	if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
	else cookies.remove('user', { path: '/' });
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
	const options = {
		body: JSON.stringify({ identifier:username, password:password }),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
	};
	try {
		const response = yield call(fetchJSON, LOCAL_URI+'/users/login', options);
		// console.log(response)
		if(response.token){
			setSession(response);
			yield put(loginUserSuccess(response));
		}else{
			throw response
		}
	} catch (error) {
		console.log("------------------------------------")
		// console.log(error,"error")
		let message = 'Something went to wrong';
		switch (error.code) {
			case 500:
				message = error.description
				break;
			case 401:
				message = 'Invalid credentials';
				break;
			default:
				message = error.message;
		}
		// console.log(message,"message")
		yield put(loginUserFailed(message));
		setSession(null);
	}
}

/**
 * Login the user
 * @param {*} payload - response
 */
function* sociallogin({ payload: response }) {
	const options = {
		body: JSON.stringify({response:response.response }),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
	};
	try {
		const response = yield call(fetchJSON, LOCAL_URI+'/users/socialLogin', options);
		// console.log(response)
		if(response.token){
			setSession(response);
			yield put(loginUserSuccess(response));
		}else{
			throw response
		}
	} catch (error) {
		console.log("------------------------------------")
		// console.log(error,"error")
		let message = 'Something went to wrong';
		switch (error.code) {
			case 500:
				message = error.description
				break;
			case 401:
				message = 'Invalid credentials';
				break;
			default:
				message = error.message;
		}
		// console.log(message,"message")
		yield put(loginUserFailed(message));
		setSession(null);
	}
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
	console.log("logout")
	try {
		setSession(null);
		yield call(() => {
			history.push('/account/login');
		});
	} catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
	const options = {
		body: JSON.stringify({ fullname, email, password }),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
	};

	try {
		const response = yield call(fetchJSON, LOCAL_URI+'/users/register', options);
		yield put(registerUserSuccess(response));
	} catch (error) {
		let message;
		switch (error.status) {
			case 500:
				message = 'Internal Server Error';
				break;
			case 401:
				message = 'Invalid credentials';
				break;
			default:
				message = error;
		}
		yield put(registerUserFailed(message));
	}
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email, history } }) {
	console.log(history)
	const options = {
		body: JSON.stringify({ email }),
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
	};
	try {
		const response = yield call(fetchJSON, LOCAL_URI+'/users/sendemail', options);
		console.log(response)
		if(response.code === 200){
			console.log(history)
			// yield put(forgetPasswordSuccess(response.message));
			history.push('/account/reset-password/'+response.data);
		}else{
			throw response
		}
		// console.log(response)
	} catch (error) {
		let message;
		switch (error.code || error.statusCode) {
			case 500:
				message = 'Internal Server Error';
				break;
			case 401:
				message = 'Invalid credentials';
				break;
			default:
				message = error.message;
		}
		yield put(forgetPasswordFailed(message));
	}
}

export function* watchLoginUser(): any {
	yield takeEvery(LOGIN_USER, login);
}

export function* watchSoicalLoginUser(): any {
	yield takeEvery(SOCIAL_LOGIN_USER, sociallogin);
}
export function* watchLogoutUser(): any {
	// console.log("watchLogoutUser")
	yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
	yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword(): any {
	yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga(): any {
	yield all([fork(watchLoginUser),fork(watchSoicalLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
